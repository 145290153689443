<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="field vdatepicker control has-icons-right">
                <v-date-picker
                  class="calendar_input"
                  clearable
                  is-range
                  mode='range'
                  v-model='dateFilter'
                  :input-props='{ placeholder: "Fecha" }'
                  @input="getListByDate"
                />
                <span class="icon is-right">
                  <span class="material-icons text_primary_green">today</span>
                </span>
              </div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="field control has-icons-right">
                <input
                  class="input input_reset"
                  type="text"
                  placeholder="Buscador"
                  v-model="searchText"
                  @input="searchUser"
                >
                <span class="icon is-right">
                  <span class="material-icons">search</span>
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div class="columns is-multiline">
          <div class="column is-12 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="salesList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p
                    :class="getStatusColor(scope.row.status_id)"
                  >
                    {{ scope.row.status_name }}
                  </p>
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-12">
            <base-pagination
              :page="page"
              :pages="parseInt(pages)"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

const DEFAULT_DISPLAY_SIZE = 50
const PENDIENTE = 1
const EN_PROCESO = 2
const ENVIADO = 3

export default {
  name: 'QuotationMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue')
  },
  data () {
    return {
      loading: false,
      page: 1,
      pages: 1,
      dateFilter: {},
      searchText: '',
      columnsData: [
        {
          id: 'quote_number',
          header: 'Nº de cotización',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-cotizacion', params: { quotationId: row.id } })
        },
        { id: 'customer', header: 'Cliente', accessor: (row) => `${row.name} ${row.last_name}` },
        { id: 'datePurchase', header: 'Fecha de cotización', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'email', header: 'Correo electrónico' },
        { id: 'status', name: 'status', header: 'Estatus' }
      ],
      salesList: []
    }
  },
  methods: {
    ...mapActions(['getQuotes', 'csvDownloadQuotes']),
    async getQuotesList (page, search, date) {
      let startDate = ''
      let endDate = ''
      if (date) {
        startDate = moment(this.dateFilter.start).format('YYYY-MM-DD 00:00:00')
        endDate = moment(this.dateFilter.end).format('YYYY-MM-DD 23:59:59')
      }
      const { quotes, total, totalPages } = await this.getQuotes({ page: page, limit: DEFAULT_DISPLAY_SIZE, search: search, startDate: startDate, endDate: endDate })
      this.total = total
      this.pages = totalPages
      this.salesList = quotes
      this.loading = false
    },
    searchUser ({ target }) {
      this.page = 1
      if (target.value.length >= 1 || target.value.length === 0) {
        this.loading = true
        this.searchText = target.value.length === 0 ? '' : target.value
        this.$router.push(
          { name: 'cotizaciones', query: { page: this.page, search: target.value } }
        ).catch(() => {})
        this.getQuotesList(this.page, target.value, this.dateFilter)
      }
    },
    getListByDate () {
      this.loading = true
      this.getQuotesList(this.page, this.searchText, this.dateFilter)
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.$router.push({ name: 'cotizaciones', query: { page: this.page, search: this.searchText } })
      this.getQuotesList(this.page, this.searchText, this.dateFilter)
    },
    async csvDownloadQuotesAction () {
      const { payload } = await this.csvDownloadQuotes({ search: this.searchText, startDate: this.startDate, endDate: this.endDate })
      if (payload) {
        const exportName = 'Cotizaciones.csv'
        const blob = new Blob([payload], { type: 'text/csv;charset=utf-8;' })
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, exportName)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', exportName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }
      }
      return payload
    },
    getStatusColor (statusId) {
      if (statusId === PENDIENTE) {
        return 'text_yellow'
      } else if (statusId === EN_PROCESO) {
        return 'text_aqua_ligth'
      } else if (statusId === ENVIADO) {
        return 'text_green'
      } else {
        return 'text_cancel'
      }
    }
  },
  beforeMount () {
    this.loading = true
    const { page, search } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.searchText = search === '' ? this.searchText : search
    this.dateFilter = ''
    this.getQuotesList(this.page, this.searchText, this.dateFilter)
  }
}
</script>

<style lang="scss" scoped>
  .vdatepicker {
    width: 250px;
  }
  select {
    width: 200px;
  }

  .level-item {
    justify-content: flex-start;
  }

  @media screen and (max-width: 768px) {
    .level-item > *, select {
      width: 100%;
    }
  }
</style>
